<template>
  <router-view />
</template>
<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import router from "./router";
// import Pusher from "pusher-js";
import axios from "axios";

export default {
  setup() {
    let store = useStore();
    const authdata = localStorage.getItem("auth");
    if (authdata) {
      // console.log("working");
      store.dispatch("setAuth", JSON.parse(authdata));
    }
    let fetchShop = async () => {
      let token = store.state.auth.token;
      document.title = "Loading...";
      let response = await axios.get(store.getters.getCustomerApi.shopInfoApi, {
        headers: { Authorization: `Bearer ${token}` },
      });
      let payload = {
        shop_name: response.data.name,
        shop_phone: response.data.phone,
        shop_address: response.data.address,
        voucher_photo:
          response.data.voucher_photo == "no"
            ? false
            : response.data.voucher_photo,
        shop_photo:
          response.data.shop_photo == "no" ? false : response.data.shop_photo,
      };
      let condition = response.data.voucher_type ? true : false;
      document.title = response.data.name ?? "";
      store.dispatch("setIsReceipt", condition);
      store.dispatch("setShopName", payload);
    };
    onMounted(async (_) => {
      if (localStorage.getItem("api")) {
        store.dispatch("setCustomerApi", localStorage.getItem("api"));
      }

      if (localStorage.getItem("api")) {
        store.dispatch("setCustomerApi", localStorage.getItem("api"));
      }
      if (localStorage.getItem("sale_person")) {
        store.dispatch(
          "setSalePerson",
          JSON.parse(localStorage.getItem("sale_person"))
        );
      }
      fetchShop();
      // from scanner machine
      var barcode = "";
      var interval;
      document.addEventListener("keydown", function (evt) {
        if (interval) clearInterval(interval);
        if (evt.code == "Enter") {
          if (barcode) handleBarcode(barcode);
          barcode = "";
          return;
        }
        if (evt.key.length == 1) {
          // console.log("shift lr p");
          barcode += evt.key;
        }
        interval = setInterval(() => (barcode = ""), 20);
      });
      function handleBarcode(scanned_barcode) {
        console.log(scanned_barcode);
        document.getElementById(`${scanned_barcode}`).click();
      }
    });
  },
  components: {},
};
</script>
<style lang="scss"></style>
