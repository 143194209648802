<template>
  <div class="row print-hide">
    <div class="col-12">
      <nav class="navbar navbar-expand-lg navbar-light bg-light vh-8">
        <div class="container-fluid">
          <a class="navbar-brand fw-bold" href="javascript:void(0)">{{
            shop_name
          }}</a>
          <div
            class="navbar-nav align-items-center"
            v-if="$route.name == 'home'"
          >
            <div class="nav-item navbar-search-wrapper mb-0">
              <a
                class="nav-item nav-link search-toggler px-0"
                href="javascript:void(0);"
              >
                <div class="input-group border rounded shadow">
                  <input
                    v-model="keyword"
                    type="text"
                    class="form-control"
                    placeholder="Search Product"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    @keyup.enter="searching"
                  />
                  <button
                    type="button"
                    class="btn rounded-pill btn-icon btn-label-primary"
                  >
                    <i class="bi bi-search"></i>
                  </button>
                </div>
              </a>
            </div>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0 align-items-center">
              <!-- <li class="nav-item navbar-dropdown dropdown-user dropdown me-3 hover">
                <i class="bi bi-gear h5" @click="passcodeRequest"></i>

              </li> -->
              <li class="nav-item ms-1">
                <button
                  v-if="!isCheckIn"
                  class="btn btn-success check-btn dropdown-toggle"
                  data-bs-toggle="modal"
                  data-bs-target="#saleperson"
                >
                  Check In
                </button>
                <button
                  v-else
                  class="btn btn-danger check-btn"
                  @click="checkOutPerson"
                >
                  Check Out
                </button>
                <div
                  class="modal fade"
                  id="saleperson"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  data-bs-backdrop="static"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Please Check In
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="close"
                          hidden
                        ></button>
                      </div>
                      <div class="modal-body">
                        <!-- <select
                          class="form-select form-select-lg mb-3"
                          aria-label=".form-select-lg example"
                          v-model="select_person_id"
                        >
                          <option value="" disabled>
                            Choose your identity
                          </option>
                          <option
                            v-for="sale_people in sale_peoples"
                            :key="sale_people.id"
                            :value="sale_people.id"
                          >
                            {{ sale_people.saleperson_name }}
                            ({{ sale_people.address }} -
                            {{ sale_people.phone_number }})
                          </option>
                        </select> -->
                        <v-select
                          :options="sale_peoples"
                          label="saleperson_name"
                          placeholder="Please Choose Sale Person"
                          v-model="select_person_id"
                        >
                          <template v-slot:option="option">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <div>
                                {{ option.saleperson_name }}<br />
                                {{ option.address }}
                              </div>
                              {{ option.phone_number }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <div class="modal-footer">
                        <button
                          v-if="selected_sale_person?.id"
                          type="button"
                          class="btn btn-secondary"
                          data-bs-dismiss="modal"
                          :disabled="loading"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="selectPerson"
                        >
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item navbar-dropdown dropdown-user dropdown">
                <a
                  class="nav-link"
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#userbox"
                >
                  <div class="avatar avatar-online">
                    <img
                      :src="shop_img == false ? '/logo.png' : shop_img"
                      alt=""
                      class="rounded-circle"
                    />
                  </div>
                </a>
                <div
                  class="modal fade"
                  id="userbox"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <div
                          class="d-flex flex-column justify-content-center align-items-center"
                        >
                          <img
                            :src="shop_img == false ? '/logo.png' : shop_img"
                            alt=""
                            width="100"
                            height="100"
                            class="rounded-circle"
                          />
                          <div class="userbox-content mt-4">
                            <h5>
                              <b>Name : </b
                              >{{ selected_sale_person?.name ?? "" }}
                            </h5>
                            <h5>
                              <b>Address : </b
                              >{{ selected_sale_person?.address ?? "" }}
                            </h5>
                            <h5>
                              <b>Phone : </b
                              >{{ selected_sale_person?.phone ?? "" }}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="nav-item ms-1">
                <button
                  type="button"
                  class="btn btn-label-danger"
                  @click="handleLogout"
                >
                  Logout<i class="bi bi-box-arrow-right ms-3"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import { confirm } from "@/composables/useConfirm";
import { ref, watch, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { requestPasscode } from "@/composables/useRequestPasscode";
import { disableShopAccessPopUp } from "@/composables/useDisableShopAccess";

import { alert } from "@/composables/useAlert";
import Swal from "sweetalert2";
import axios from "axios";
import vSelect from "vue-select";
export default {
  components: { vSelect },
  setup() {
    let store = useStore();
    let router = useRouter();
    let keyword = ref(store.state.order.keyword);
    let select_person_id = ref("");
    let loading = ref(false);
    let authUser = store.state.auth.authUser;
    const shop_name = computed(() => store.state.order.shop_info.shop_name);
    const shop_img = computed(
      () =>
        store.state.auth.sale_person?.photo ??
        store.state.order.shop_info.shop_photo
    );
    let sale_peoples = ref("");
    let selected_sale_person = computed(() => store.state.auth.sale_person);
    let isCheckIn = computed(() =>
      store.state.auth.sale_person?.id ? true : false
    );
    let handleLogout = () =>
      confirm("Sure to logout?", "", async () => {
        let person_id = store.state.auth.sale_person.id;
        let token = store.state.auth.token;
        store.dispatch("logOut");
        store.dispatch("clearOrder");
        router.push({ name: "login" });
        if (person_id) {
          await axios.post(
            store.getters.getCustomerApi.checkinPerson,
            { sale_person_id: person_id, status: 2 },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          store.dispatch("setSalePerson", "");
          localStorage.removeItem("sale_person");
        }
      });
    let passcodeRequest = async () => {
      let { passcode } = await requestPasscode();
      passcode.value == 111
        ? router.push({ name: "product-warehouse" })
        : alert("error", "Wrong Passcode", "");
    };
    let searching = () => {
      store.dispatch("searchProduct");
    };
    let fetchSalePeoples = async () => {
      let token = store.state.auth.token;
      let response = await axios.get(
        store.getters.getCustomerApi.getSalePersonsApi,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      let datas = response.data.data;
      sale_peoples.value = datas;
    };
    let checkOutPerson = async () => {
      Swal.fire({
        title: "Are you sure want to checkout?",
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let person_id = store.state.auth.sale_person.id;
          let token = store.state.auth.token;
          await axios.post(
            store.getters.getCustomerApi.checkinPerson,
            { sale_person_id: person_id, status: 2 },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          store.dispatch("setSalePerson", "");
          localStorage.removeItem("sale_person");
          select_person_id.value = "";
          disableShopAccessPopUp();
        } else if (result.isDenied) {
          // Swal.fire("Changes are not saved", "", "info");
        }
      });
      return;
    };
    let selectPerson = async () => {
      let person_id = select_person_id.value.id;
      // let warehouse_id = store.state.auth.
      let token = store.state.auth.token;
      loading.value = true;
      let response = await axios
        .post(
          store.getters.getCustomerApi.checkinPerson,
          { sale_person_id: person_id, status: 1 },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          loading.value = false;
          store.dispatch("setSalePerson", response.data);
          localStorage.setItem("sale_person", JSON.stringify(response.data));
          document.getElementById("close").click();
        });
    };
    onMounted(async () => {
      fetchSalePeoples();
      if (!selected_sale_person?.value?.id) {
        disableShopAccessPopUp();
      }
    });
    watch(keyword, (_) => store.dispatch("setKeyword", keyword.value));
    return {
      handleLogout,
      authUser,
      keyword,
      passcodeRequest,
      shop_name,
      shop_img,
      searching,
      sale_peoples,
      isCheckIn,
      checkOutPerson,
      select_person_id,
      selectPerson,
      loading,
      selected_sale_person,
    };
  },
};
</script>
<style>
.check-btn {
  border-radius: 18px !important;
}
div#typeahead_id_wrapper.simple-typeahead
  input#typeahead_id.simple-typeahead-input {
  margin-bottom: 0;
  width: 100% !important;
  padding: 10px !important;
  border-radius: 10px !important;
}
.userbox-content {
  text-align: justify;
}
</style>
